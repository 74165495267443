import React, { Component, FunctionComponent, useEffect, useState } from 'react';
import useEffectAsync from '../hooks/useEffectAsync';
import { Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';
import { Icons, SquareIcon } from '../components/SquareIcon';
import '../styles/Dashboard.scss';
import { ProductViewer } from '../components/ProductViewer';
import { ProductConfig, AppConfig } from '../models/authProductConfig';
import { Redirect } from 'react-router-dom';
import logo from '../assets/iptor.png';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { LoaderContainer, LoaderContainerType } from '../components/LoaderContainer';

export type LoadingSetters = {};
interface realmConfig {
  email: string;
  realm: string;
  authUrl: string;
}
export const Dashboard: FunctionComponent = () => {
  let [realmSettings, setRealmSettings] = useState<realmConfig | undefined | null>(undefined);
  let [appSettings, setAppSettings] = useState<AppConfig | undefined | null>(undefined);

  const getRealmProductSettings = async (data: any) => {
    let res: AxiosResponse;
    try {
      res = await axios.post('/auth-config', data);
      return res;
    } catch (error) {
      const err = error as AxiosError;
      res = { status: err.response?.status ?? 500, data: err.response } as AxiosResponse;
      return res;
    }
  };

  const logout = () => {
    localStorage.removeItem('realmSettings');
    window.location.href = '/logout';
  };
  const manageAccount = () => {
    if (realmSettings?.authUrl !== undefined && realmSettings?.realm !== undefined) {
      window.location.href =
        realmSettings?.authUrl +
        '/realms/' +
        realmSettings?.realm +
        '/account?referrer=' +
        appSettings?.authClientName +
        '&referrer_uri=' +
        encodeURIComponent(window.location.href);
    }
  };

  useEffectAsync(async () => {
    const localRealmSettings = localStorage.getItem('realmSettings');

    if (localRealmSettings) {
      const config = JSON.parse(localStorage.getItem('realmSettings') ?? '') as realmConfig; //TODO: refactor
      setRealmSettings(config);

      const settings = await getRealmProductSettings(config);
      if (settings?.status === 200) {
        setAppSettings(settings.data as AppConfig);
      } else {
        setAppSettings(null);
      }
    } else {
      setRealmSettings(null);
      setAppSettings(null);
    }
  }, []);

  if (appSettings === undefined) {
    return <LoaderContainer loaderType={LoaderContainerType.Full} text={'Loading...'} />;
  } else if (appSettings === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="dashboard-container">
        <Navbar bg="white" fixed="top" className="header-container" style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)' }}>
          <Nav.Item className="navbar-left-item">
            <img height="24px" src={logo} />
          </Nav.Item>

          <Nav.Item className="company-name-dispatcher mr-auto navbar-right-item">
            <h6 style={{ marginLeft: '1rem' }}>{appSettings.customer}</h6>
          </Nav.Item>

          <Nav className="custom-nav-wrapper ">
            <NavDropdown
              id="user-action"
              title={
                <div className="custom-avatar-body">
                  <span>
                    {appSettings.userName
                      ?.match(/(\b\S)?/g)
                      ?.join('')
                      .match(/(^\S|\S$)?/g)
                      ?.join('')
                      .toUpperCase()}
                  </span>
                </div>
              }
              className="custom-avatar"
            >
              <NavDropdown.Header>
                {appSettings.userName} <br />
                <small>{appSettings.customer}</small>
              </NavDropdown.Header>
              <NavDropdown.Item onClick={() => manageAccount()}>Manage Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar>

        <div className="body-container">
          <ProductViewer categories={appSettings.categories} />
        </div>

        <div className="footer">
          <p className="copyright-notice">&copy; Iptor 2021</p>
          <img height="24px" src={logo} />
          <p className="powered-by-notice">Powered by Iptor</p>
        </div>
      </div>
    );
  }
};
