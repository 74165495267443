import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Dashboard } from './views/Dashboard';
import { Login } from './views/Login';

export function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/app" component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
