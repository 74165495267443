import React, { useEffect, useRef, useState, ReactElement } from 'react';
import '../styles/Icon.scss';

export enum Icons {
  Account = 'account',
  Add = 'add',
  AdditionalTasks = 'additional_tasks',
  AddressBook = 'address_book',
  AlertManagement = 'alert_management',
  Alert = 'alert',
  Archive = 'archive',
  BarGraph = 'bar_graph',
  Binoculars = 'binoculars',
  Book = 'book',
  Bookmark = 'bookmark',
  Briefcase = 'briefcase',
  BrowserLoad = 'browser_load',
  Browser = 'browser',
  Calculator = 'calculator',
  Calendar = 'calendar',
  CalendarDisabled = 'calendar-disablvg',
  CalendarError = 'calendar-errvg',
  Changeorg = 'changeorg',
  Chat1 = 'chat1',
  CheckCircle = 'check_circle',
  Check = 'check',
  ChevronDown = 'chevron_down',
  ChevronDown2 = 'chevron_down2',
  ChevronRight = 'chevron_right',
  ChevronUp = 'chevron_up',
  ChevronUp2 = 'chevron_up2',
  Clock = 'clock',
  Close = 'close',
  ColumnSpacing = 'column_spacing',
  Columns = 'columns',
  Component = 'component',
  ContextAddress = 'context_address',
  ContextSelect = 'context_select',
  Copy = 'copy',
  Csv = 'csv',
  Cut = 'cut',
  Dashboard = 'dashboard',
  DocOutputNotify = 'doc_output_notify',
  Doc = 'doc',
  DollarSign = 'dollar_sign',
  Download = 'download',
  DropdownArrow = 'dropdown-arrow',
  EditContext = 'edit_context',
  EditPromptArrow = 'edit-prompt-arrow',
  Edit = 'edit',
  EditPrompt1 = 'editprompt_1',
  EditPrompt2 = 'editprompt_2',
  EmptyBox = 'empty_box',
  ErrorReport = 'error_report',
  ExcelDocument = 'excel_document',
  Eye = 'eye',
  Facebook = 'facebook',
  FilterAscend = 'filter_ascend',
  FilterClear = 'filter_clear',
  FilterDescend = 'filter_discend',
  Filter = 'filter',
  Flag = 'flag',
  Folder = 'folder',
  FormatText = 'format_text',
  Google1 = 'googlevg',
  Google = 'google',
  GridView = 'grid_view',
  HamburgerMenu = 'hamburger_menu',
  HelpCircle = 'help_circle',
  Help = 'help',
  Hide = 'hide',
  History = 'history',
  HtmlDoc = 'html_doc',
  IbsEnterprise = 'ibs_enterprise',
  Information = 'information',
  Invoice = 'invoice',
  ItemSerial = 'item_serial',
  KebabMenu1 = 'kebab_menu1',
  KebabMenu2 = 'kebab_menu2',
  Keyboard = 'keyboard',
  LeftArrowCircle = 'left_arrow_circle',
  LeftArrow = 'left_arrow',
  Link = 'link',
  Linkedin = 'linkedin',
  LocationPin = 'location_pin',
  Mail = 'mail',
  MenuOption = 'menu_option',
  MenuoptNext = 'menuopt_next',
  MenuoptionTransactionNext = 'menuoption_transaction',
  Notification = 'notification',
  OpenBook = 'open_book',
  OpenFolder = 'open_folder',
  Open = 'open',
  Package = 'package',
  PanelMessages = 'panel_messages',
  Paperclip1 = 'paperclip-1',
  Paperclip = 'paperclip',
  Paste = 'paste',
  Pdf = 'package',
  PersonalSettings = 'personal_settings',
  PrintView = 'print_view',
  PrinterView = 'printer_view',
  Printer = 'printer',
  Profile = 'profile',
  Project = 'project',
  PromptArrow = 'prompt-arrow',
  Qlink = 'qlink',
  Query = 'query',
  Refresh = 'refresh',
  Refresh2 = 'refresh2',
  Reorder = 'reorder',
  Requisite = 'requisite',
  RightArrowCircle = 'right_arrow_circle',
  RightArrow = 'right_arrow',
  Save = 'save',
  Search = 'search',
  SearchView = 'search_view',
  ServerView = 'server_view',
  Settings = 'settiings',
  Shipping = 'shipping',
  ShoppingCart = 'shopping_cart',
  Signout = 'signout',
  Sitemap = 'sitemap',
  Skype = 'skype',
  Sliders = 'sliders',
  Star = 'star',
  SupplyChain = 'supply_chain',
  SystemManagement = 'system_management',
  TableRefresh = 'table_refresh',
  Table = 'table',
  Tasks = 'tasks',
  TextError = 'text-errvg',
  Tool = 'tool',
  Tool2 = 'tool2',
  Trash = 'trash',
  Upload = 'upload',
  Url = 'url',
  UserGroup = 'user_group',
  View = 'view',
  Warehouse = 'warehouse',
  Wikipedia = 'wikipedia',
  WordDocument = 'word_document',
  Workc = 'workc',
  XmlDoc = 'xml_doc',
  AutoplayStart = 'autoplay-start',
  AutoplayStop = 'autoplay-stop',
  AutoplayToStart = 'autoplay-to-start',
  AutoplayToStop = 'autoplay-to-stop'
}

function useDynamicSVGImport(name: string) {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/${name}.svg`)).default;
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
}

export interface SquareIconProps extends React.SVGProps<SVGSVGElement> {
  children: Icons | string;
  className?: string;
  size?: string;
}

export const SquareIcon: React.FC<SquareIconProps> = ({ children, size = '', className = '' }): ReactElement<any, any> | null => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(children as string);
  if (error) {
    return <>{error.message}</>;
  }
  if (loading) {
    return <>{'Loading...'}</>;
  }
  if (SvgIcon) {
    return <SvgIcon className={className} height={size} width={size} />;
  }
  return null;
};
