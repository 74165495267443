import React from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { Carousel } from './Carousel';
import { ProductCategory, Product } from '../models/authProductConfig';
import { SquareIcon } from './SquareIcon';
import '../styles/ProductViewer.scss';
import '../styles/Carousel.scss';
import '../styles/Button.scss';
export type ProductViewerProps = { categories: ProductCategory[]; header?: Product };

export const sorting = (a: number | undefined, b: number | undefined) => {
  // sort ascending: 1, 2, ..., undefined
  return !a && !b ? 0 : !a ? 1 : !b ? -1 : a < b ? -1 : a > b ? 1 : 0;
};

export const ProductViewer: React.FC<ProductViewerProps> = ({ categories, header }) => {
  return (
    <Container className="text-center overflow-hidden" fluid>
      {header && (
        <div className="product-group product-header">
          <Row>
            <Col className="text-left" sm={6}>
              <h3>{header.name}</h3>
              <h6>{header.description}</h6>
              <Button className="btn-header" target="_blank" href={header.url} variant="outline-primary" size="lg">
                OPEN
              </Button>
            </Col>
            <Col sm={6}>
              <SquareIcon size="200px">{header.icon ?? ''}</SquareIcon>
            </Col>
          </Row>
        </div>
      )}
      {categories
        .sort((a, b) => sorting(a.sequence, b.sequence))
        .map((cat: ProductCategory) => (
          <Carousel {...cat} />
        ))}
    </Container>
  );
};
