import React, { Component, FormEvent, FunctionComponent, ReactElement, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Button, Image } from 'react-bootstrap';
//import { Button } from '../components/Button';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { Router } from 'react-router-dom';
import '../styles/Login.scss';
import '../styles/Button.scss';

export type LoadingSetters = {};

export const Login: FunctionComponent<LoadingSetters> = (props: LoadingSetters) => {
  const [validated, setValidated] = useState(false);
  const [errorOnServer, setErrorOnServer] = useState<boolean | undefined>(false);
  const [errorOnServerText, setErrorOnServerText] = useState<string | undefined>('Customer not found for provided account!');

  const getAuthConfig = async (email: string) => {
    let res: AxiosResponse;
    try {
      res = await axios.post('/auth-realm', { email: email } as AxiosRequestConfig);
      return res;
    } catch (error) {
      const err = error as AxiosError;
      res = { status: err.response?.status ?? 500, data: err.response } as AxiosResponse;
      return res;
    }
  };

  const runDashboard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const data = new FormData(e.currentTarget);
    const email = data.get('email')?.toString() ?? '';

    const connectionConfig = await getAuthConfig(email ?? '');
    if (connectionConfig?.status === 200) {
      localStorage.setItem(
        'realmSettings',
        JSON.stringify({ realm: connectionConfig?.data.realm, authUrl: connectionConfig?.data.authUrl, email: email })
      ); //TODO: refactor
      window.location.href = '/app';
    } else {
      setErrorOnServer(true);
      if (connectionConfig?.data?.data) setErrorOnServerText(connectionConfig.data.data);
    }
  };

  return (
    <Container
      fluid
      style={{
        height: '100vh',
        background: 'url("' + process.env.REACT_APP_LOGIN_BACKGROUND + '"), url("/Sign-in-page-bg-image-metro-1440x1024.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Row style={{ height: '100vh' }} className="align-items-end">
        <Col sm={0} md={7} lg={8} style={{ padding: '0px 100px', margin: '160px 0px' }}>
          <Image src="/divider.png" />
          <br />
          <Image src="/logo-white.png" style={{ margin: '32px 0px' }} />
          {/* TO-LNG:  */}
          <p className="login-banner">A milestone in the Iptor transformation journey</p>
          <p className="login-subtitle">
            {/* TO-LNG:  */}
            Iptor.com is the key platform for distribution and publishing businesses to turn digital disruption into digital opportunity.
          </p>
        </Col>
        <Col style={{ height: '100vh' }} sm={12} md={5} lg={4}>
          <div
            style={{
              height: 'calc(100vh - 30px)',
              margin: '15px 0',
              padding: '24px 0px',
              position: 'relative',
              overflow: 'auto'
            }}
            className="bg-white"
          >
            <Image style={{ paddingLeft: '24px' }} src={'/logo-color.png'} />
            <h6 className="welcome-message" style={{ marginTop: '32px' }}>
              Hi {/* TO-LNG: Localization.instance.getString('TXT_HI') */}
              <br />
              Welcome back {/* TO-LNG: Localization.instance.getString('TXT_THERE') */}
            </h6>

            <Form
              noValidate
              validated={validated}
              style={{ marginTop: '72px', padding: '0 48px' }}
              onSubmit={runDashboard}
              onChange={() => {
                setValidated(false);
                setErrorOnServer(false);
              }}
            >
              <Form.Group>
                {/* TO-LNG: Localization.instance.getString('TXT_USER_ID') */}
                <Form.Label className="text-left">Email ID</Form.Label>
                {/* TO-LNG: Enter email*/}
                <Form.Control id="email" name="email" placeholder="Enter email" type="email" required />
                {/* TO-LNG: Localization.instance.getString('TXT_USER_ID_REQUIRED') */}
                <Form.Control.Feedback type="invalid">Please provide valid email!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                {/* server validation for email */}
                <Form.Control id="server" name="server" isInvalid={errorOnServer} hidden />
                <Form.Control.Feedback type="invalid">{errorOnServerText}</Form.Control.Feedback>
              </Form.Group>
              <Button
                // disabled={this.state.loading}
                style={{
                  marginTop: '64px',
                  width: '100%',
                  background: '#00a3a5',
                  height: '40px',
                  fontSize: '12px',
                  padding: '12px auto'
                }}
                type="submit"
              >
                {/* TO-LNG: Localization.instance.getString('TXT_LOGIN_PAGE_LOGIN_BTN') */}
                LOGIN
              </Button>
            </Form>
            <div style={{ position: 'absolute', bottom: 20 }}>
              <p className="copyright-notice-login">
                {/* TO-LNG: Localization.instance.getString('TXT_COPYRIGHT_FOOTER') */}
                Copyright © 2021 - Iptor Sweden AB
                <br />
                {/* TO-LNG: Localization.instance.getString('TXT_PRIVACY_POLICY') */}
                {/* TO-LNG: Localization.instance.getString('TXT_UK_LEGAL') */}
                {/* TO-LNG: Localization.instance.getString('TXT_CODE_OF_CONDUCT') */}
                Privacy Policy | UK Legal | Code of Conduct
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
