import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Product, ProductCategory } from '../models/authProductConfig';
import { Icons, SquareIcon } from './SquareIcon';
import { sorting } from './ProductViewer';

export const Carousel: FunctionComponent<ProductCategory> = ({ name, description, products }) => {
  const [page, setPage] = useState(0);
  const rowRef = useRef<any>(null);
  useEffect(() => {
    if (rowRef.current) {
      const target = page * rowRef.current.clientWidth;
      rowRef.current.style.transform = `translate(-${target}px)`;
    }
  }, [page]);

  return (
    <div className="product-group">
      <h3>{name}</h3>
      <h6>{description}</h6>
      <Row ref={rowRef} className="product-carousel">
        {products
          .sort((a, b) => sorting(a.sequence, b.sequence))
          .map(({ name, description, icon, url }: Product) => {
            return (
              <Col lg={4} className="product-carousel-item">
                <SquareIcon size="200px">{icon ?? ''}</SquareIcon>
                <h3>{name}</h3>
                <h6>{description}</h6>
                <Button target="_blank" href={url} variant="outline-primary" size="lg">
                  OPEN
                </Button>
              </Col>
            );
          })}
        {Array(3 - (products.length % 3))
          .fill(1)
          .map(() => (
            <Col lg={4} className="product-carousel-item" />
          ))}
      </Row>
      {products.length > 3 && (
        <Button onClick={() => setPage(page - 1)} disabled={page === 0} className="nav-button prev-button">
          <SquareIcon size="48px">{Icons.LeftArrowCircle}</SquareIcon>
        </Button>
      )}
      {products.length > 3 && (
        <Button onClick={() => setPage(page + 1)} disabled={(page + 1) * 3 > products.length} className="nav-button next-button">
          <SquareIcon size="48px">{Icons.LeftArrowCircle}</SquareIcon>
        </Button>
      )}
    </div>
  );
};
